<template>
  <div class="container container-sm" style="margin-top: 2rem;">
    <h1 style="font-weight: 900; !important">ANSWER THAT AND STAY FASHIONABLE</h1>
    <p>This tool is intended for private and personal use by Mattias Strubbe only. We cannot be held liable for any damages resulting from public use of this tool. In addition, this tool is provided as is and with no warranty or guarantee of its accuracy.</p>
    <textarea class="form-control" rows="6" v-model="promptInput" ref="input" :disabled="loading"></textarea>
    <br>
    
    <button class="btn btn-lg btn-danger" :disabled="disabled" @click="submit">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16" v-if="!loading">
        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
      </svg>
    </button>
    <button class="btn btn-link" :disabled="disabled" @click="reset" style="margin-left: auto;">
      Clear prompt
    </button>
    
    <div  v-for="(result, i) in results" :key="`result-${i}`">
      <br><br>
      <div class="card">
        <div class="card-body">
          <strong class="card-title">{{ result.prompt }}</strong>
          <code><pre style="white-space: pre-wrap;" v-html="result.poem"></pre></code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { StripeCheckout } from '@vue-stripe/vue-stripe';
// import { StripeElementCard } from '@vue-stripe/vue-stripe';
const OpenAI = require('openai-api');
const OPENAI_API_KEY = process.env.VUE_APP_OPENAI_API_KEY;
const openai = new OpenAI(OPENAI_API_KEY);
// const openai = new OpenAI("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");

export default {
  name: 'App',
  components: {
    // StripeCheckout,
    // StripeElementPayment,
    // StripeElementCard
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      promptInput: 'What is the answer to life the universe and everything',
      prompt: '',
      // prompt: 'Write me a valentines day poem in Portugese for my friend Marius. He likes playing progressive rock, hiking and climbing and he loves dogs.',
      results: [],
      token: null,
      lineItems: [
        {
          price: 'price_1MTw0UFBgYolbZc5s42XH77a', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'http://localhost:8082/payment-success',
      cancelURL: 'http://localhost:8082/payment-cancelled',
    }
  },
  computed: {
    disabled() {
      return this.loading || !this.promptInput
    }
  },
  methods: {
    fettchPoem() {
      this.loading = true;
      (async () => {
        const gptResponse = await openai.complete({
          engine: 'text-davinci-003',
          prompt: this.prompt,
          maxTokens: 700,
          temperature: 0.9,
          topP: 1,
          presencePenalty: 0,
          frequencyPenalty: 0,
          bestOf: 1,
          n: 1,
          stream: false,
          stop: ['exit']
        });

        // console.log(gptResponse.data);
        // console.log(this.$stripe)
        this.results.unshift({
          prompt: this.prompt,
          poem: gptResponse.data.choices[0]?.text
        })
        // this.result = gptResponse.data.choices[0]?.text
        this.loading = false
      })();
    },
    submit () {
      this.prompt = this.promptInput;
      this.fettchPoem();
    },
    reset () {
      this.prompt = '';
      this.promptInput = '';
      this.$refs.input.focus();
    },
  },
  mounted() {
    // console.log('mounted');
    // console.log(process.env.VUE_APP_OPENAI_API_KEY);
    // this.generatePaymentIntent();
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
